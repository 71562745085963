import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n  ",
        "\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
export var Divider = styled.div.withConfig({
    componentId: "sc-d4f6ae66-0"
})(_templateObject(), function(props) {
    return "margin: 0 0 ".concat(props.marginBottom, "rem 0;");
}, function(props) {
    return props.isHidden ? "display: none;" : "";
});
export default Divider;
