import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  word-break: break-word;\n  display: flex;\n  flex-direction: column;\n  margin-left: 18px;\n\n  div {\n    width: 100%;\n    font-style: normal;\n    text-align: left;\n    color: ",
        ";\n    align-items: center;\n  }\n\n  p {\n    margin: 0 auto;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 400;\n  color: ",
        " !important;\n  margin: 0 auto;\n  ",
        "\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 400;\n  margin: 0 auto;\n  ",
        "\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n\n  ",
        "\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  div {\n    font-family: ",
        ";\n    font-weight: 400;\n    margin: 0 auto;\n    margin-top: 3px;\n    ",
        "\n\n    b {\n      font-family: ",
        ";\n      font-weight: 700;\n    }\n\n    @media (min-width: ",
        ") {\n      margin-top: 0;\n      ",
        "\n    }\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: row;\n  margin-top: 4px;\n\n  &.extraClass {\n    padding: 4px 10px;\n    gap: 4px;\n    width: 155px;\n    height: 24px;\n    background: #00697c;\n    opacity: 0.8;\n    border-radius: 20px;\n  }\n\n  svg {\n    width: 25px;\n    vertical-align: middle;\n    margin-right: 7.57px;\n    ",
        "\n  }\n\n  @media (min-width: ",
        ") {\n    margin-top: 9px;\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  div {\n    font-family: ",
        ";\n    font-weight: 700;\n    ",
        "\n    margin: 0 auto;\n    ",
        "\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont } from "@vfit/shared/themes";
export var Container = styled.div.withConfig({
    componentId: "sc-fbe8ebc7-0"
})(_templateObject(), colors.$262626);
export var Detail = styled.div.withConfig({
    componentId: "sc-fbe8ebc7-1"
})(_templateObject1(), fonts.regular, colors.$bebebe, pxToCssFont(16, 22), breakpoints.tablet, pxToCssFont(18, 24));
export var Title = styled.div.withConfig({
    componentId: "sc-fbe8ebc7-2"
})(_templateObject2(), fonts.exbold, pxToCssFont(20, 26), breakpoints.tablet, pxToCssFont(20, 30), function(props) {
    switch(props.type){
        case 3:
            return "{\n          font-family: ".concat(fonts.regular, ";\n          font-weight: 700;\n          margin-bottom: 8px;\n          ").concat(pxToCssFont(16, 22), "\n          @media (min-width: ").concat(breakpoints.tablet, ") {\n            ").concat(pxToCssFont(18, 24), "\n            margin-bottom: 12px;\n          }\n        }");
        default:
            return "";
    }
});
export var Description = styled.div.withConfig({
    componentId: "sc-fbe8ebc7-3"
})(_templateObject3(), fonts.regular, pxToCssFont(16, 22), fonts.regular, breakpoints.tablet, pxToCssFont(18, 24));
export var ContainerExtra = styled.div.withConfig({
    componentId: "sc-fbe8ebc7-4"
})(_templateObject4(), function(props) {
    return "stroke: ".concat(props.color);
}, breakpoints.tablet);
export var Extra = styled.div.withConfig({
    componentId: "sc-fbe8ebc7-5"
})(_templateObject5(), fonts.regular, pxToCssFont(16, 22), function(props) {
    return "color: ".concat(props.color, " !important;");
});
