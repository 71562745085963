export var colors = {
    $transparent: "transparent",
    // black
    $262626: "#262626",
    $0d0d0d: "#0d0d0d",
    $353535_40: "#35353566",
    $000000_16: "#00000029",
    $25282B: "#25282B",
    // grey
    $f2f2f2: "#f2f2f2",
    $bebebe: "#bebebe",
    $ebebeb: "#ebebeb",
    $b99898: "#b99898",
    $7e7e7e: "#7e7e7e",
    $eeeeee: "#eee",
    $333333: "#333333",
    $313131: "#313131",
    $9a9a9a: "#9a9a9a",
    $565656: "#565656",
    // orange
    $eb6100: "#eb6100",
    // green
    $008a00: "#008a00",
    $00697c: "#00697c",
    $0096AD: "#0096AD",
    // red
    $e60000: "#e60000",
    $bd0000: "#bd0000",
    $a10000: "#a10000",
    $820000: "#820000",
    $c30000: "#c30000",
    //pink
    $9c2aa0: "#9c2aa0",
    // white
    $ffffff: "#fff",
    $ffffff_80: "#fffc",
    $ffffff_60: "#fff9",
    //Cyan
    $007c92: "#007c92"
};
