import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  width: 100vw;\n  height: 100vh;\n  animation: fade-in 0.9s ease-in forwards;\n  background-color: rgba(0, 0, 0, 50%);\n\n  // z-index: 20;\n\n  @keyframes fade-in {\n    from {\n      opacity: 0;\n    }\n\n    to {\n      opacity: 1;\n    }\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  bottom: 0;\n  left: 0;\n  opacity: 0;\n  position: fixed;\n  ",
        "\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  z-index: 4;\n  position: fixed;\n  left: 0;\n  bottom: 0;\n  width: 100%;\n  height: ",
        ";\n  transform: translate3d(0, calc(100% - ",
        "), 0);\n  background-color: ",
        ";\n  border-radius: ",
        ";\n  overflow: hidden;\n\n  &.slide-up {\n    @keyframes slide-out-up {\n      from {\n        transform: translate3d(0, calc(100% - ",
        "), 0);\n      }\n\n      to {\n        transform: translate3d(0, 0, 0);\n      }\n    }\n\n    animation-name: slide-out-up;\n    animation-duration: 0.8s;\n    animation-fill-mode: forwards;\n  }\n\n  &.slide-down {\n    @keyframes slide-out-down {\n      from {\n        transform: translate3d(0, 0, 0);\n      }\n\n      to {\n        transform: translate3d(0, calc(100% - ",
        "), 0);\n      }\n    }\n\n    animation-name: slide-out-down;\n    animation-duration: 0.8s;\n    animation-fill-mode: forwards;\n  }\n\n  &.upward {\n    animation: increase-up 0.4s forwards ease-out;\n  }\n\n  &.downward {\n    animation: decrease 0.4s forwards ease-out;\n  }\n\n  @keyframes increase-up {\n    from {\n      bottom: -140px;\n    }\n\n    to {\n      bottom: 0;\n    }\n  }\n\n  @keyframes decrease {\n    from {\n      bottom: 0;\n    }\n\n    to {\n      bottom: -140px;\n    }\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  body {\n  overflow: hidden;\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  position: relative;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 16px;\n\n  @media (min-width: ",
        ") {\n    padding: 18px 32px;\n  }\n\n  @media (min-width: ",
        ") {\n    padding: 20px 80px;\n  }\n\n  border-top: ",
        ";\n  border-bottom: 0.5px solid ",
        ";\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  min-width: 123px;\n  max-width: 65%;\n\n  button,\n  a {\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    width: unset;\n    min-width: ",
        ";\n\n    @media (min-width: ",
        ") {\n      min-width: ",
        ";\n    }\n\n    @media (min-width: ",
        ") {\n      min-width: ",
        ";\n    }\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n    display: block;\n    color: ",
        ";\n    font-family: ",
        ";\n    font-weight: 400;\n    ",
        "\n  }\n\n  button {\n    min-width: ",
        ";\n\n    @media (min-width: ",
        ") {\n      min-width: ",
        ";\n    }\n\n    @media (min-width: ",
        ") {\n      min-width: ",
        ";\n    }\n  }\n\n  p {\n    padding: 0;\n    margin: 0;\n    font-family: ",
        ";\n    font-weight: 400;\n    ",
        "\n\n    @media (min-width: ",
        ") {\n      ",
        "\n    }\n\n    .rec {\n      ",
        "\n\n      @media (min-width: ",
        ") {\n        ",
        "\n      }\n\n      @media (min-width: ",
        ") {\n        ",
        "\n      }\n    }\n  }\n\n  @media (min-width: ",
        ") {\n    display: flex;\n    width: 100%;\n    align-items: center;\n    justify-content: space-between;\n\n    .button-slide-container {\n      margin-right: 16px;\n    }\n    \n  }\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n  width: 160px;\n  height: 50px;\n  background: linear-gradient(0.25turn, transparent, ",
        ", transparent),\n    linear-gradient(#eee, #eee), linear-gradient(#eee, #eee);\n  background-repeat: no-repeat;\n  animation: loading 1.5s infinite;\n\n  @media (min-width: ",
        ") {\n    width: 250px;\n  }\n\n  @keyframes loading {\n    to {\n      background-position: 315px 0, 0 0, 0 190px, 50px 195px;\n    }\n  }\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _tagged_template_literal([
        "\n    p {\n      ",
        ";\n\n      @media (min-width: ",
        ") {\n        ",
        "\n      }\n"
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
function _templateObject9() {
    var data = _tagged_template_literal([
        "\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex: 1;\n  padding: 0;\n  margin: 0;\n  background-color: transparent;\n  border: none;\n  min-width: inherit !important;\n\n  p {\n    margin: 0;\n    font-family: ",
        ";\n    font-weight: 400;\n    ",
        "\n    color: ",
        ";\n    text-transform: uppercase;\n  }\n\n  svg {\n    margin-left: 4px;\n    width: 4px;\n    height: 8px;\n    transform: rotate(",
        "deg);\n  }\n"
    ]);
    _templateObject9 = function _templateObject9() {
        return data;
    };
    return data;
}
function _templateObject10() {
    var data = _tagged_template_literal([
        "\n  -ms-overflow-style: none; /* for Internet Explorer, Edge */\n  scrollbar-width: none;\n\n  ::-webkit-scrollbar {\n    display: none; /* for Chrome, Safari, and Opera */\n  }\n\n  overflow-y: auto;\n  height: calc(100% - ",
        "px);\n  padding: 0 24px;\n\n  @media (min-width: ",
        ") {\n    max-width: calc(100% - ",
        "px);\n    margin: 0 auto;\n  }\n\n  a {\n    color: ",
        ";\n  }\n"
    ]);
    _templateObject10 = function _templateObject10() {
        return data;
    };
    return data;
}
function _templateObject11() {
    var data = _tagged_template_literal([
        "\n  position: absolute;\n  top: 0;\n  left: 0;\n  background-color: ",
        ";\n  width: 100%;\n  padding: 0;\n"
    ]);
    _templateObject11 = function _templateObject11() {
        return data;
    };
    return data;
}
function _templateObject12() {
    var data = _tagged_template_literal([
        "\n  min-height: ",
        "px;\n  width: ",
        "%;\n  border-top-right-radius: 8px;\n  border-bottom-right-radius: 8px;\n  background-color: ",
        ";\n  transition: width 1s ease;\n"
    ]);
    _templateObject12 = function _templateObject12() {
        return data;
    };
    return data;
}
import styled, { createGlobalStyle, css } from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont, pxToRem } from "@vfit/shared/themes";
export var progressbarHeight = 6;
var OpaqueActive = css(_templateObject());
export var OpaqueBackground = styled.div.withConfig({
    componentId: "sc-d4a90539-0"
})(_templateObject1(), function(p) {
    return p.isActive == "slide-up" ? OpaqueActive : "";
});
export var MainContainer = styled.div.withConfig({
    componentId: "sc-d4a90539-1"
})(_templateObject2(), function(p) {
    return p.isApp ? "80vh" : "100%";
}, function(props) {
    return "".concat(props.activationBoxHeight, "px");
}, colors.$ffffff, pxToRem(6, 6, 0, 0), function(props) {
    return "".concat(props.activationBoxHeight, "px");
}, function(props) {
    return "".concat(props.activationBoxHeight, "px");
});
export var OverlayGlobalStyle = createGlobalStyle(_templateObject3());
export var ActivationBox = styled.div.withConfig({
    componentId: "sc-d4a90539-2"
})(_templateObject4(), breakpoints.tablet, breakpoints.desktop, function(props) {
    return props.withProgressbar ? "unset" : "0.5px solid ".concat(colors.$bebebe);
}, colors.$bebebe);
export var CtaContainer = styled.div.withConfig({
    componentId: "sc-d4a90539-3"
})(_templateObject5(), pxToRem(97), breakpoints.tablet, pxToRem(209), breakpoints.desktop, pxToRem(220));
export var Details = styled.div.withConfig({
    componentId: "sc-d4a90539-4"
})(_templateObject6(), colors.$262626, fonts.regular, pxToCssFont(14, 20), pxToRem(97), breakpoints.tablet, pxToRem(209), breakpoints.desktop, pxToRem(220), fonts.exbold, pxToCssFont(24, 32), breakpoints.desktop, pxToCssFont(36, 48), pxToCssFont(18, 24), breakpoints.tablet, pxToCssFont(24, 32), breakpoints.desktop, pxToCssFont(28, 32), breakpoints.desktop);
export var DetailsLoading = styled.div.withConfig({
    componentId: "sc-d4a90539-5"
})(_templateObject7(), colors.$ffffff, breakpoints.tablet);
export var TopLabel = styled.span.withConfig({
    componentId: "sc-d4a90539-6"
})(_templateObject8(), pxToCssFont(12, 16), breakpoints.desktop, pxToCssFont(16, 22));
export var ShowDetailsCta = styled.button.withConfig({
    componentId: "sc-d4a90539-7"
})(_templateObject9(), fonts.regular, pxToCssFont(14, 18), colors.$262626, function(props) {
    return props.rotation ? props.rotation : "0";
});
// element a style moved from MainContainer to ChildrenContainer
export var ChildrenContainer = styled.div.withConfig({
    componentId: "sc-d4a90539-8"
})(_templateObject10(), function(props) {
    return props.activationBoxHeight + 40;
}, breakpoints.desktop, function(props) {
    return props.ctaContainerWidth;
}, colors.$262626);
export var ProgressbarContainer = styled.div.withConfig({
    componentId: "sc-d4a90539-9"
})(_templateObject11(), colors.$bebebe);
export var Bar = styled.div.withConfig({
    componentId: "sc-d4a90539-10"
})(_templateObject12(), progressbarHeight, function(props) {
    return props.stepCoverage;
}, colors.$e60000);
