import { useMedia } from "react-use";
import { breakpoints } from "@vfit/shared/themes";
import { handleEnv } from "@vfit/shared/providers";
// DISABLE THIS FUNCTION FOR HOTFIX IN PROD
// defined defaultState to prevent a hydration mismatches when server side rendering
var isSetDefaultState = function() {
    var defaultMediaQueryValue = handleEnv("NX_MEDIA_QUERY_DEFAULT_STATE") || "";
    if (defaultMediaQueryValue !== "") {
        var ref;
        return ((ref = defaultMediaQueryValue === null || defaultMediaQueryValue === void 0 ? void 0 : defaultMediaQueryValue.toString()) === null || ref === void 0 ? void 0 : ref.toLowerCase()) === "true";
    }
    return undefined;
};
export function useMediaQuery(breakpoint, logic) {
    var query = "(".concat(logic, "-width: ").concat(breakpoints[breakpoint], ")");
    return useMedia(query);
}
