import * as C from "./components";
export var svgObj = {
    account: C.Account,
    arrowDown: C.ArrowDown,
    arrowLeft: C.ArrowLeft,
    arrowRight: C.ArrowRight,
    calendar: C.Calendar,
    call: C.Call,
    camera: C.Camera,
    celebrate: C.Celebrate,
    chevronDown: C.ChevronDown,
    chevronDownBtn: C.ChevronDownBtn,
    chevronLeft: C.ChevronLeft,
    chevronRight: C.ChevronRight,
    chevronRightBtn: C.ChevronRightBtn,
    chevronUp: C.ChevronUp,
    circleStatusError: C.CircleStatusError,
    circleStatusInfo: C.CircleStatusInfo,
    circleStatusSuccess: C.CircleStatusSuccess,
    close: C.Close,
    closeBtn: C.CloseBtn,
    closeLs: C.CloseLs,
    copy: C.Copy,
    dotLs: C.DotLs,
    eye: C.Eye,
    eyeSlash: C.EyeSlash,
    error: C.Error,
    leaf: C.Leaf,
    locateMe: C.LocateMe,
    location: C.Location,
    magnifyingGlassPlus: C.MagnifyingGlassPlus,
    magnifyingGlassMinus: C.MagnifyingGlassMinus,
    menu: C.Menu,
    minus: C.Minus,
    myTrips: C.MyTrips,
    plus: C.Plus,
    plusBtn: C.PlusBtn,
    search: C.Search,
    success: C.Success,
    spinner: C.Spinner,
    thumbUp: C.ThumbUp,
    thumbDown: C.ThumbDown,
    tick: C.Tick,
    tickCircle: C.TickCircle,
    tickLs: C.TickLs,
    warning: C.Warning,
    warningBig: C.WarningBig,
    warningDot: C.WarningDot,
    infoAndHelp: C.InfoAndHelp
};
