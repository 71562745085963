import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  height: 45px;\n\n  @media (min-width: ",
        ") {\n    width: 207px;\n  }\n\n  @media (min-width: ",
        ") {\n    width: 324px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints } from "@vfit/shared/themes";
var ButtonSlideCTA = styled.div.withConfig({
    componentId: "sc-5ed3ffaa-0"
})(_templateObject(), breakpoints.mobile, breakpoints.desktop);
export default ButtonSlideCTA;
