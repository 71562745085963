import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  height: 100vh;\n  left: 0;\n  width: 100vw;\n  position: fixed;\n  top: 0;\n  z-index: 4;\n  ",
        "\n  ",
        ";\n\n  @keyframes slide-down {\n    from {\n      transform: translateY(0);\n    }\n\n    to {\n      transform: translateY(100%);\n    }\n  }\n\n  @keyframes slide-up {\n    from {\n      transform: translateY(100%);\n    }\n\n    to {\n      transform: translateY(0);\n    }\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  background-color: ",
        ";\n  border-radius: ",
        ";\n  bottom: 0;\n  display: flex;\n  justify-content: center;\n  height: 80%;\n  left: 0;\n  padding: ",
        ";\n  position: fixed;\n  right: 0;\n  width: 100vw;\n  z-index: 5;\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  overflow-y: auto;\n  width: calc(100vw - ",
        ");\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { colors, pxToRem } from "@vfit/shared/themes";
export var Background = styled.div.withConfig({
    componentId: "sc-c5b24791-0"
})(_templateObject(), function(p) {
    return p.colorTheBg ? "background-color: rgba(0, 0, 0, 50%);" : "";
}, function(p) {
    return "animation: slide-".concat(p.show ? "up" : "down", " ").concat(p.animationTime / 1000, "s forwards");
});
export var Drawer = styled.section.withConfig({
    componentId: "sc-c5b24791-1"
})(_templateObject1(), colors.$ffffff, pxToRem(6, 6, 0, 0), pxToRem(48, 0));
export var Frame = styled.div.withConfig({
    componentId: "sc-c5b24791-2"
})(_templateObject2(), pxToRem(88));
