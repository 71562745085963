import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  margin-bottom: 64px;\n\n  * {\n    box-sizing: inherit;\n  }\n\n  @media (min-width: ",
        ") {\n    display: flex;\n    align-items: center;\n    flex-wrap: wrap;\n    transform-style: preserve-3d;\n    animation-fill-mode: unset !important;\n    margin-bottom: 180px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  position: relative;\n  width: 100%;\n  max-width: 1280px;\n  margin: 0 auto;\n  border-radius: 15px;\n  padding-bottom: 32px;\n\n  @media (min-width: ",
        ") {\n    display: flex;\n    align-items: stretch;\n    height: 596px;\n    position: relative;\n    transform-style: inherit;\n    padding: 0;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  padding: 56px 24px 32px;\n\n  @media (min-width: ",
        ") {\n    border-radius: 20px 0 0 20px;\n    width: 296px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    z-index: 2;\n    position: relative;\n    padding: 0;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  text-align: center;\n\n  @media (min-width: ",
        ") {\n    padding: 0 24px 0 48px;\n  }\n\n  @media (min-width: ",
        ") {\n    text-align: left;\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  div {\n    margin: 0;\n  }\n\n  p,\n  span {\n    margin: 0;\n    ",
        "\n    font-style: normal;\n    font-weight: 400;\n    color: ",
        ";\n    font-family: ",
        ";\n    margin-bottom: 12px;\n  }\n\n  @media (min-width: ",
        ") {\n    p,\n    span {\n      ",
        "\n      margin-bottom: 20px;\n    }\n  }\n\n  @media (min-width: ",
        ") {\n    p,\n    span {\n      ",
        "\n      margin-bottom: 20px;\n    }\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  font-style: normal;\n  font-weight: 400;\n  ",
        "\n  color: ",
        ";\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  padding-top: 12px;\n\n  button {\n    width: 221px;\n  }\n\n  @media (min-width: ",
        ") {\n    padding-top: 16px;\n  }\n\n  @media (min-width: ",
        ") {\n    padding-top: 26px;\n\n    button {\n      width: 100%;\n    }\n  }\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n  display: inline-block;\n  white-space: nowrap;\n\n  @media (min-width: ",
        ") {\n    white-space: unset;\n    width: 100%;\n    max-width: 320px;\n    display: flex;\n    flex-flow: column nowrap;\n    justify-content: center;\n    margin-top: -14px;\n    align-items: end;\n  }\n\n  :first-child {\n    div:first-child {\n      margin-left: 24px;\n\n      @media (min-width: ",
        ") {\n        margin-left: 0;\n      }\n    }\n\n    @media (min-width: ",
        ") {\n      transform: translateY(64px);\n      margin-top: 0;\n    }\n  }\n\n  :last-child {\n    @media (min-width: ",
        ") {\n      border-radius: 0 20px 20px 0;\n      margin-top: 0;\n    }\n  }\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _tagged_template_literal([
        "\n  overflow-x: auto;\n  overflow-y: hidden;\n  white-space: nowrap;\n  width: 100%;\n  -ms-overflow-style: none;\n  scrollbar-width: none;\n  padding-left: 24px;\n  margin-bottom: 24px;\n  display: flex;\n  will-change: auto;\n\n  &::-webkit-scrollbar {\n    display: none;\n  }\n\n  @media (min-width: ",
        ") {\n    overflow: visible;\n    white-space: unset;\n    transform-origin: center;\n    height: 100%;\n    width: calc(100% - 296px);\n    display: flex;\n    align-items: stretch;\n    padding: 0;\n    margin-bottom: 0;\n  }\n\n  @media (min-width: ",
        ") {\n    &.layout-3,\n    &.layout-4 {\n      justify-content: flex-end;\n\n      /* stylelint-disable-next-line selector-type-no-unknown */\n      ",
        ":first-child {\n        padding-left: 0;\n        padding-right: 12px;\n        align-items: end;\n      }\n\n      /* stylelint-disable-next-line selector-type-no-unknown */\n      ",
        ":last-child {\n        display: none;\n      }\n\n      ",
        " {\n        width: unset;\n      }\n    }\n\n    &.layout-6 {\n      /* stylelint-disable-next-line selector-type-no-unknown */\n      ",
        ":last-child {\n        transform: translateY(64px);\n      }\n    }\n  }\n\n  transition-duration: 0.3s;\n  transition-timing-function: ease-in;\n  transition-delay: 0s;\n"
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
function _templateObject9() {
    var data = _tagged_template_literal([
        "\n  // perspective: 9px;\n  // transform-style: preserve-3d;\n  // perspective-origin: center;\n"
    ]);
    _templateObject9 = function _templateObject9() {
        return data;
    };
    return data;
}
function _templateObject10() {
    var data = _tagged_template_literal([
        "\n  height: 100%;\n  width: 100%;\n  min-height: 100%;\n  min-width: 100%;\n  overflow: hidden;\n  z-index: -1;\n  object-fit: cover;\n  object-position: center;\n  border-radius: 15px;\n  position: ",
        ";\n  top: ",
        ";\n  left: ",
        ";\n"
    ]);
    _templateObject10 = function _templateObject10() {
        return data;
    };
    return data;
}
function _templateObject11() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 700;\n  color: ",
        ";\n\n  img {\n    width: 18px;\n  }\n"
    ]);
    _templateObject11 = function _templateObject11() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont } from "@vfit/shared/themes";
export var ExperiencesWrapper = styled.div.withConfig({
    componentId: "sc-dbdff460-0"
})(_templateObject(), fonts.regular, breakpoints.bigDesktop);
export var ExperiencesContainer = styled.div.withConfig({
    componentId: "sc-dbdff460-1"
})(_templateObject1(), breakpoints.bigDesktop);
export var ExperiencesContainerLeft = styled.div.withConfig({
    componentId: "sc-dbdff460-2"
})(_templateObject2(), breakpoints.bigDesktop);
export var ExperiencesContainerLeftText = styled.div.withConfig({
    componentId: "sc-dbdff460-3"
})(_templateObject3(), breakpoints.tablet, breakpoints.bigDesktop);
export var ExperiencesContainerLeftTitle = styled.div.withConfig({
    componentId: "sc-dbdff460-4"
})(_templateObject4(), pxToCssFont(35, 38), colors.$ffffff, fonts.exbold, breakpoints.tablet, pxToCssFont(42, 52), breakpoints.bigDesktop, pxToCssFont(40, 48));
export var ExperiencesContainerLeftParagraph = styled.div.withConfig({
    componentId: "sc-dbdff460-5"
})(_templateObject5(), pxToCssFont(18, 24), colors.$ffffff, breakpoints.bigDesktop, pxToCssFont(24, 30));
export var ExperienceContainerButton = styled.div.withConfig({
    componentId: "sc-dbdff460-6"
})(_templateObject6(), breakpoints.tablet, breakpoints.bigDesktop);
export var ExperiencesLayoutColumn = styled.div.withConfig({
    componentId: "sc-dbdff460-7"
})(_templateObject7(), breakpoints.bigDesktop, breakpoints.bigDesktop, breakpoints.bigDesktop, breakpoints.bigDesktop);
export var ExperiencesContainerRight = styled.div.withConfig({
    componentId: "sc-dbdff460-8"
})(_templateObject8(), breakpoints.bigDesktop, breakpoints.bigDesktop, ExperiencesLayoutColumn, ExperiencesLayoutColumn, ExperiencesLayoutColumn, ExperiencesLayoutColumn);
export var Parallax = styled.div.withConfig({
    componentId: "sc-dbdff460-9"
})(_templateObject9());
export var DivImgContainer = styled.div.withConfig({
    componentId: "sc-dbdff460-10"
})(_templateObject10(), function(props) {
    return props.absolute ? "absolute" : "relative";
}, function(props) {
    return props.absolute ? 0 : "";
}, function(props) {
    return props.absolute ? 0 : "";
});
export var DisclaimerContainer = styled.div.withConfig({
    componentId: "sc-dbdff460-11"
})(_templateObject11(), fonts.regular, colors.$7e7e7e);
