export var IActionType;
(function(IActionType) {
    IActionType[IActionType["CUSTOM_URL"] = 1] = "CUSTOM_URL";
    IActionType[IActionType["CALL_ME_NOW"] = 4] = "CALL_ME_NOW";
    IActionType[IActionType["COVERAGE_TOOL"] = 5] = "COVERAGE_TOOL";
    IActionType[IActionType["LOCAL_URL"] = 7] = "LOCAL_URL";
    IActionType[IActionType["SHOW_DETAIL"] = 9] = "SHOW_DETAIL";
    IActionType[IActionType["COVERAGE_TOOL_WITH_REDIRECT"] = 10] = "COVERAGE_TOOL_WITH_REDIRECT";
    IActionType[IActionType["CHECKOUT_MODAL_MOBILE"] = 11] = "CHECKOUT_MODAL_MOBILE";
    IActionType[IActionType["WHATSAPP_URL"] = 12] = "WHATSAPP_URL";
    IActionType[IActionType["RETRY"] = 13] = "RETRY";
    IActionType[IActionType["POPUP_DETAIL"] = 14] = "POPUP_DETAIL";
    IActionType[IActionType["LEAD_MODAL"] = 15] = "LEAD_MODAL";
    IActionType[IActionType["POST_COVERAGE"] = 16] = "POST_COVERAGE";
    IActionType[IActionType["IFRAME_URL"] = 17] = "IFRAME_URL";
    IActionType[IActionType["CALL_ME_NOW_SUNRISE"] = 18] = "CALL_ME_NOW_SUNRISE";
})(IActionType || (IActionType = {}));
export var IActionStyle;
(function(IActionStyle) {
    IActionStyle[IActionStyle["PRIMARY"] = 1] = "PRIMARY";
    IActionStyle[IActionStyle["SECONDARY"] = 2] = "SECONDARY";
    IActionStyle[IActionStyle["TERTIARY"] = 3] = "TERTIARY";
})(IActionStyle || (IActionStyle = {}));
