import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  padding-left: 80px;\n  display: flex;\n  justify-content: space-between;\n  height: 72px;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  svg {\n    margin: 12px auto;\n    width: ",
        ";\n    height: auto;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  padding: 0;\n  margin: 0;\n  display: flex;\n  margin-left: 68px;\n  gap: 32px;\n  margin-right: auto;\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  align-items: stretch;\n  list-style: none;\n\n  a {\n    border-top: 1px solid transparent;\n    border-bottom: 1px solid transparent;\n    display: flex;\n    align-items: center;\n    color: #333;\n    text-decoration: none;\n    transition: 0.3s all ease;\n  }\n\n  a:link {\n    font-family: ",
        ";\n    font-style: normal;\n    font-weight: 400;\n    ",
        "\n  }\n\n  a.showLine {\n    border-bottom: 2px solid ",
        ";\n    color: ",
        ";\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  padding: 0;\n  margin: 0;\n  margin-right: 119px;\n  align-items: center;\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  list-style: none;\n  margin-left: 40px;\n  width: 20px;\n  height: auto;\n\n  a:hover svg path.stroke {\n    stroke: ",
        ";\n  }\n\n  a:hover svg path.fill {\n    fill: ",
        ";\n  }\n\n  .search-container {\n    position: relative;\n    display: inline-block;\n    margin: 4px 2px;\n    height: 28px;\n    width: 26px;\n    vertical-align: bottom;\n  }\n\n  .searchbutton {\n    position: absolute;\n    cursor: pointer;\n    ",
        "\n    width: 100%;\n    margin: 0;\n    padding: 0;\n  }\n\n  .search:focus + .searchbutton {\n    transition-duration: 0.4s;\n    background-color: white;\n    color: black;\n  }\n\n  .search {\n    position: absolute;\n    left: 49px; /* Button width-1px (Not 50px/100% because that will sometimes show a 1px line between the search box and button) */\n    background-color: white;\n    outline: none;\n    padding: 0;\n    width: 0;\n    height: 100%;\n    z-index: 10;\n    transition-duration: 0.4s;\n    border: 1px solid black;\n  }\n\n  .search:focus {\n    width: 363px; /* Bar width+1px */\n    padding: 0 16px 0 0;\n  }\n\n  .expandright {\n    left: auto;\n    right: 200px;\n  }\n\n  .expandright:focus {\n    padding: 0 0 0 16px;\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { colors, fonts, pxToCssFont, pxToCssFontSize } from "@vfit/shared/themes";
export var MainContainer = styled.div.withConfig({
    componentId: "sc-57803e9d-0"
})(_templateObject());
export var LogoWrapper = styled.div.withConfig({
    componentId: "sc-57803e9d-1"
})(_templateObject1(), function(param) {
    var small = param.small;
    return small ? "28px" : "48px";
});
export var Menu = styled.ul.withConfig({
    componentId: "sc-57803e9d-2"
})(_templateObject2());
export var MenuList = styled.li.withConfig({
    componentId: "sc-57803e9d-3"
})(_templateObject3(), fonts.regular, pxToCssFont(20, 28), colors.$e60000, colors.$e60000);
export var ActionsMenu = styled.ul.withConfig({
    componentId: "sc-57803e9d-4"
})(_templateObject4());
export var ActionsMenuList = styled.li.withConfig({
    componentId: "sc-57803e9d-5"
})(_templateObject5(), colors.$e60000, colors.$e60000, pxToCssFontSize(22));
